import './_functions.js';
import { lazyLoad } from './functions/lazyLoad.js';
import { onClickBtn } from './functions/onAnyClick.js';
import { onScrollEvent } from './functions/onScrollEvent.js';
import { setSwipers } from './functions/allSwiper.js';
import { setGsap, gsapAnim } from './functions/setGsap.js';
import { Fancybox } from "@fancyapps/ui";
import { observeBody } from './functions/watchBody.js';
import { setMouseMovePoint } from './functions/setMouseMovePoint.js';
import { countAnime } from './functions/countNum.js';
import { applyMask } from './functions/applyMask.js';
import { typewrite } from './functions/typewriter.js';

let firstTime = true;

function toggleFilterButton() {

  const placeBody = document.querySelector(".place");
  const placeButtons = document.querySelector(".place__buttons");

  if (placeBody && placeButtons) {
      const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
              if (entry.isIntersecting) {
                  placeButtons.classList.add("show");
              } else {
                  placeButtons.classList.remove("show");
              }
          });
      });

      observer.observe(placeBody);
  }

}

function hoverAnim(){
  if(document.querySelectorAll('.unique__list ')){
    document.querySelectorAll('.unique__list li:not(:last-child)').forEach(item => {
      item.addEventListener('mouseenter', () => {
          document.querySelectorAll('.unique__images .swiper-slide').forEach(image => {
              image.classList.remove('active');
          });
          const targetImage = document.getElementById(item.dataset.target);
          if (targetImage) {
              targetImage.classList.add('active');
          }
      });
  });
  }
}

window.addEventListener('DOMContentLoaded', () => {
  onScrollEvent();
  onClickBtn();
  setSwipers();
  lazyLoad();
  applyMask('.is-phone');
  observeBody(['no-scrolling', 'is-start', 'loading']);
  setMouseMovePoint();
  countAnime();
  typewrite();
  toggleFilterButton()
  hoverAnim()

  Fancybox.bind("[data-fancybox]");

  window.addEventListener('wheel', () => {
    if (firstTime) {
        firstTime = false;
        setTimeout(() => {
          if (document.querySelector('.special') || document.querySelector('.team')) {
              setGsap();
          }
          if (document.querySelector('.comfort__body')) {
              gsapAnim();
          }
      }, 1000);
    }
  });

  window.addEventListener('scroll', toggleFilterButton);
});


