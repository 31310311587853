import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

export const setGsap = () => {
    const smallTablet = matchMedia('(max-width: 768px)');
    const tablet = matchMedia('(max-width: 1024px)');

    const animDetail = (selector, frames) => {
        const offset = window.innerWidth <= 1440 ? 1 : (window.innerWidth / 1440) * 1.5;
        return gsap.fromTo(
            selector,
            {
                y: 0,
                scrollTrigger: {
                    trigger: selector,
                    scrub: 0.5,
                    end: "bottom center-=100",
                },
            },
            {
                scrollTrigger: {
                    trigger: selector,
                    scrub: 0.5,
                    end: "bottom center-=100",
                },
                keyframes: [
                    {
                        y: (frames[1] - frames[0]) * offset,
                    },
                    {
                        y: (frames[2] - frames[0]) * offset,
                    },
                ],
            }
        );
    };

    const initializeAnimations = () => {
        ScrollTrigger.getAll().forEach(trigger => trigger.kill());

        if (document.querySelector(".special") && !smallTablet.matches) {
            if (tablet.matches) {
                gsap.to(".special-slide-1", {
                    yPercent: 20,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".special",
                        scrub: 0.5,
                        start: "top-=100",
                        end: "center-=100",
                    },
                });
            } else {
                gsap.from(".special-slide-1", {
                    yPercent: 50,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".special",
                        scrub: 0.5,
                        end: "center-=150",
                    },
                });
            }

            gsap.from(".special-slide-2", {
                yPercent: 120,
                ease: "none",
                scrollTrigger: {
                    trigger: ".special",
                    scrub: 0.5,
                    end: "center-=150",
                },
            });

            gsap.from(".special__big", {
                yPercent: 80,
                ease: "none",
                scrollTrigger: {
                    trigger: ".special",
                    scrub: 0.5,
                    end: "center-=150",
                },
            });
        }

        if (document.querySelector(".team") && !tablet.matches) {
            animDetail(".team-slide-1", [425.5, 305.5, 204]);
            animDetail(".team-slide-2", [337, 100, 28.5]);
            animDetail(".team-slide-3", [71, 36, 20]);
        }
    };

    initializeAnimations();

    window.addEventListener('resize', () => {
        initializeAnimations();
    });
};

export const gsapAnim = () => {
    const tablet = matchMedia("(max-width: 768px)");
    const mainImage = document.querySelector('.comfort__main-image');

    const changeImage = (slide) => {
        const newImageSrc = slide.dataset.image;
        if (mainImage.src !== newImageSrc) {
            gsap.to(mainImage, {
                opacity: 0,
                duration: 0.3,
                onComplete: () => {
                    mainImage.src = newImageSrc;
                    gsap.to(mainImage, { opacity: 1, duration: 0.3 });
                }
            });
        }
        document.querySelectorAll('.comfort__swiper .swiper-slide').forEach(el => el.classList.remove('active'));
        slide.classList.add('active');
    };

    const setupAnimation = () => {
        if (document.querySelector(".comfort__body") && !tablet.matches) {
            document.querySelectorAll('.comfort__swiper .swiper-slide').forEach(slide => {
                ScrollTrigger.create({
                    trigger: slide,
                    start: "top center",
                    end: "bottom center",
                    onEnter: () => changeImage(slide),
                    onEnterBack: () => changeImage(slide),
                });
            });
        } else {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        }
    };

    setupAnimation();
    window.addEventListener('resize', setupAnimation);
};
